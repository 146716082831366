export const imports = {
  'docz/getting-started.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-getting-started" */ 'docz/getting-started.mdx'
    ),
  'docz/components/FormButtonField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-button-field" */ 'docz/components/FormButtonField.mdx'
    ),
  'docz/components/FormChipField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-chip-field" */ 'docz/components/FormChipField.mdx'
    ),
  'docz/components/FormDateField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-date-field" */ 'docz/components/FormDateField.mdx'
    ),
  'docz/components/FormDateRangeField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-date-range-field" */ 'docz/components/FormDateRangeField.mdx'
    ),
  'docz/components/FormDateTimeField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-date-time-field" */ 'docz/components/FormDateTimeField.mdx'
    ),
  'docz/components/FormFileUploadField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-file-upload-field" */ 'docz/components/FormFileUploadField.mdx'
    ),
  'docz/components/FormIntegerField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-integer-field" */ 'docz/components/FormIntegerField.mdx'
    ),
  'docz/components/FormMoneyField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-money-field" */ 'docz/components/FormMoneyField.mdx'
    ),
  'docz/components/FormNumberField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-number-field" */ 'docz/components/FormNumberField.mdx'
    ),
  'docz/components/FormPercentageField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-percentage-field" */ 'docz/components/FormPercentageField.mdx'
    ),
  'docz/components/FormPhoneField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-phone-field" */ 'docz/components/FormPhoneField.mdx'
    ),
  'docz/components/FormReactSelectField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-react-select-field" */ 'docz/components/FormReactSelectField.mdx'
    ),
  'docz/components/FormReadOnlyField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-read-only-field" */ 'docz/components/FormReadOnlyField.mdx'
    ),
  'docz/components/FormSelectField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-select-field" */ 'docz/components/FormSelectField.mdx'
    ),
  'docz/components/FormShowField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-show-field" */ 'docz/components/FormShowField.mdx'
    ),
  'docz/components/FormTextField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-text-field" */ 'docz/components/FormTextField.mdx'
    ),
  'docz/components/FormWeekField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-week-field" */ 'docz/components/FormWeekField.mdx'
    ),
  'docz/components/FormSwitchField.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docz-components-form-switch-field" */ 'docz/components/FormSwitchField.mdx'
    ),
}
